import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Login } from '@mui/icons-material'
import { Button, Container, Typography } from '@mui/material'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay, Image, SeoHeaders } from 'shared/components'
import { getQueryStringValue, setFormError } from 'shared/services'

import NavBar from '../../components/nav_bar'
import GuitaLogo from '../../images/guita-logo.svg'
import { ADMIN_PASSWORD_UPDATE_MUTATION } from '../../queries/auth'
import { clearCredential } from '../../services/auth'
import { translateGuitaError } from '../../services/error_messages'
import { clearManagedDomain } from '../../services/managed_domain'

import type { AdminPasswordUpdateData, AdminPasswordUpdateVars } from '../../queries/auth'
import type { PageProps } from 'gatsby'

type FormValues = Omit<AdminPasswordUpdateVars, 'resetPasswordToken'>

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Tu contraseña es muy corta')
    .max(128, 'Tu contraseña es muy larga')
    .required('Este campo es obligatorio'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    .required('Este campo es obligatorio'),
})

const PasswordUpdateForm = ({
  isSubmitting,
  isValid,
  status,
  submitted,
  submitForm,
}: FormikProps<FormValues> & { submitted: boolean }) => (
  <Form
    onSubmit={(event) => {
      event?.preventDefault()
      submitForm()
    }}
    style={{ width: '100%' }}
  >
    <Field
      required
      name='password'
      type='password'
      label='Nueva contraseña'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <Field
      required
      name='passwordConfirmation'
      type='password'
      label='Confirma tu nueva contraseña'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <Button
      disabled={submitted || isSubmitting || !isValid}
      variant='contained'
      type='submit'
      size='large'
      fullWidth
      sx={{ mt: 2 }}
    >
      Enviar
    </Button>
  </Form>
)

const PasswordUpdate = ({ location }: PageProps) => {
  const resetPasswordToken = getQueryStringValue(location.search, 'token', '')
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const [adminPasswordUpdate] =
    useMutation<AdminPasswordUpdateData, AdminPasswordUpdateVars>(ADMIN_PASSWORD_UPDATE_MUTATION, {
      errorPolicy: 'all',
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await adminPasswordUpdate({
      variables: {
        ...values,
        resetPasswordToken,
      },
    })
    const authenticatable = response.data?.adminUpdatePasswordWithToken?.authenticatable

    if (authenticatable) {
      setSubmitted(true)
      openDialog()
      clearCredential()
      clearManagedDomain()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <SeoHeaders title='Actualizar Contraseña' />
      <NavBar />
      <Container
        maxWidth='xs'
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
          gap: 2,
          px: 2,
          pt: 2,
          pb: 4,
        })}
      >
        <Image
          src={GuitaLogo}
          alt='Logo Guita'
          duration={300}
        />
        <Typography textAlign='center'>
          Ingresa tu nueva contraseña para iniciar sesión:
        </Typography>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <PasswordUpdateForm
              submitted={submitted}
              {...props}
            />
          )}
        </Formik>
      </Container>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Tu contraseña fue actualizada'
        contentText={'Ahora puedes iniciar sesión con tu email y nueva contraseña.'}
      >
        <ButtonsContainer mt={2}>
          <ButtonContainer xs={12}>
            <Button
              fullWidth
              href='/auth/login/'
              variant='contained'
              endIcon={<Login />}
              size='large'
            >
              Iniciar sesión
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}

export default PasswordUpdate
